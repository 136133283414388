import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styles } from '../../css/players.styles';
import { formatDate } from '../../util/dashboardUtil';
import { GENIE_BASE_URL } from '../../util/constants';


function TicketsTable({ tickets, loading, error }) {
  //console.log(tickets.length)
  if (!Array.isArray(tickets) || tickets.length === 0) {
    return <Typography>No tickets available</Typography>;
  }

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;
  if (tickets.length === 0) return <Typography>No open tickets found in the last week.</Typography>;
  const handleTicketClick = (ticketNumber) => {
    // Replace with your actual input value
    const username = sessionStorage.getItem('userEmail');
    const password = sessionStorage.getItem('password');
    const isAuthenticated = sessionStorage.getItem('isAuthenticated')
    const token = sessionStorage.getItem('token')

    const appLink = `${GENIE_BASE_URL}?&username=${encodeURIComponent(username)}&token=${encodeURIComponent(token)}&input=${encodeURIComponent(ticketNumber)}&flag=${encodeURIComponent('Ticket Details')}`;
    //window.open(appLink, '_blank');
    const width = Math.min(window.innerWidth * 0.8, 800); // 80% of the viewport width, max 800px
    const height = Math.min(window.innerHeight * 0.8, 600); // 80% of the viewport height, max 600px
    const popupWindow = window.open(appLink, 'popupWindow', `width=${width},height=${height},resizable=yes,scrollbars=yes`);
    if (popupWindow) {
        popupWindow.focus(); // Focus on the popup window
    } else {
        // Fallback if the popup was blocked
        window.open(appLink, '_blank'); // Open in a new tab if popup is blocked
    }

    console.log(ticketNumber)
  }

  const uniqueTickets = tickets.filter((ticket, index, self) =>
    index === self.findIndex((t) => t.ticketNumber === ticket.ticketNumber)
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={styles.table} aria-label="tickets table">
        <TableHead sx={styles.tableHead} >
          <TableRow>
            <TableCell sx={styles.tableHeadCell}>Ticket No.</TableCell>
            <TableCell sx={styles.tableHeadCell}>Client Name</TableCell>
            <TableCell sx={styles.tableHeadCell}>Subject</TableCell>
            <TableCell sx={styles.tableHeadCell}>Agent</TableCell>
            <TableCell sx={styles.tableHeadCell}>Status</TableCell>
            {/*<TableCell sx={styles.tableHeadCell}>Email</TableCell>*/}
            <TableCell sx={styles.tableHeadCell}>Received Date Time </TableCell>
            <TableCell sx={styles.tableHeadCell}>Response Date Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uniqueTickets.map((ticket) => (

            <TableRow key={ticket.id || ticket._id} sx={styles.tableRow} onClick={() => handleTicketClick(ticket.ticketNumber)}>
              <TableCell sx={styles.tableCell}>{ticket.ticketNumber}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.clientName}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.subject}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.agentName}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.status}</TableCell>
              {/* <TableCell>{ticket.email}</TableCell>*/}
              <TableCell sx={styles.tableCell}>{formatDate(ticket.createdTime)}</TableCell>
              <TableCell sx={styles.tableCell}>{formatDate(ticket.customerResponseTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TicketsTable;

