import React, { useEffect, useState } from 'react';
import Popup from './Popup.js'; // Create a reusable popup component
import { fetchPlayers } from '../../services/api';
import { processFetchedData } from '../../util/dashboardUtil';
import { set } from 'date-fns';
//import sendEmail from './sendEmail'; // Email trigger logic
import { getLocalTimeForTimezone, convertUtcToTimezone } from '../../util/dashboardUtil';

const usePrevious = (value) => {
    const [previousValue, setPreviousValue] = useState();
    const offlinePlayers = value.filter(player => !player.isConnected);
    //console.log(offlinePlayers.length);
    useEffect(() => {
        setPreviousValue(offlinePlayers);
    }, [value]);

    return previousValue;
};

const LastSeen = ({ players, setLastSeenPlayers, currentTime }) => {
    const [onlinePlayers, setOnlinePlayers] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const previousPlayers = usePrevious(players);
    const [showTime, setShowTime] = useState(false);

    //console.log(previousPlayers?previousPlayers.length:0);
    const checkLastSeen = () => {
        //const data = await fetchLastSeen();
        
        const onlineThreshold = 300; // 5 minutes in seconds
 
        // Filter for online players who are not in the offline list
        const onlinePlayers = players.length > 0 ? players.filter(player => {
            const currentTime = getLocalTimeForTimezone(player.timezone);
            const lastSeenTime = convertUtcToTimezone(player.timezone, player.lastSeen)
            //console.log(currentTime, lastSeenTime);
            if (player.isConnected) {
                //console.log(player.name);
                const timeDiff = (new Date(currentTime) - new Date(lastSeenTime)) / 1000; // Difference in seconds
                //console.log(timeDiff);
                // Check if the player is online (last seen within 5 minutes) and  in the offline list
                return previousPlayers?timeDiff <= onlineThreshold && previousPlayers.some(previousPlayer => previousPlayer.id === player.id):false;

            }
        }) : [];
        //console.log(onlinePlayers.length);
        if (onlinePlayers.length > 0) {
            setShowPopup(true);
            sessionStorage.setItem('showPopup', true);
            sessionStorage.setItem('nowOnlinePlayers', JSON.stringify(onlinePlayers));
            setLastSeenPlayers(onlinePlayers);
            setOnlinePlayers(onlinePlayers);
        }
    };

    useEffect(() => {
        checkLastSeen(); // Initial check
        // const interval = setInterval(() => {
        //     checkLastSeen();
        // }, 300000); // Poll every minute

        // return () => clearInterval(interval); // Clean up interval on unmount
    }, [players, previousPlayers]);

    return (
        <div>


            {
                //showPopup && 
                (
                    <Popup
                        players={onlinePlayers}
                        currentTime={currentTime}
                        onClose={() => {setShowPopup(false); sessionStorage.setItem('showPopup', false);}}
                    />
                )}
        </div>
    );
};

export default LastSeen;
