
// You can add other constants here as well, for example:
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.manayillconsulting.com/api';
export const GENIE_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://dev.manayillconsulting.com/MPM';
export const LOGIN_URL = process.env.REACT_APP_API_BASE_URL || 'https://maindb-dev.manayillconsulting.com/api/users/login';
//export const GENIE_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://genie.manayillconsulting.com/MPM';

export const COLORS = {
  olive: '#808000',
  whiteSmoke: '#F5F5F5',
  black: '#000000',
  deepSkyBlue: '#00BFFF',
  midnightBlue: '#191970',
  crimson: '#C23B22',
  seaGreen: '#2E8B57',
  grey: '#d3d3d3',
  slateGray: "#7E909A",
  purple: '#ADBFFF',
  pastelGreen: "#16423C",
  charcoal: "#202020",
  teal: "#1C4E80",
  coral: "#EA6A47",
  lightCyan1: "#A5D8DD",
  navyBlue: "#1C4E80",
  brightBlue: "#0091D5",
  softGray: "#F1F1F1",
  pieGreen: "#6AB187",
  pieRed: "#D32D41",
  pieYellow: "#ffb400",
  cyan: "#488A99",
  yellow: "#DBAE58",
  darkCyan: '#008B8B',
  lightCyan: '#E0FFFF',
  darkGrey: '#333333',
  white: '#FFFFFF',
  hoverRed: "#df8879",
  altGrey: "#bfcbdb",
  cyan: '#00BFFF',
  green: '#4CAF50',
  red: '#F44336',


  /**darkbrown:"#3B3030",
  brown:"#664343",
  lightbrown:"#795757",
  warmBrown:"#AF8F6F",
  beige:"#FFF0D1" **/

};

export const COLORS1 = {
  bgColor: "#232022",
  tabColor: "#998643",
  tabHoverColor: "#DCBB4F",
  tabBorder: "#F5F1EC",
  tabSelected: "#DCBB4F",
  tableBG: "#849698",
  altRowBG: "#ADBBB2",
  componentBG: '#ADBBB2',
  mustardYellow: '#DCBB4D',
  lightBeige: "#EAE8E2",
  beige: '#D3CEB6',
}

export const orgIds = {
  mpm: "mDSNj",
  bjcta: "0OSY3E",
  irondale: "EgSG9Y",
  birmingham: "1jS2nM",
  bwwb: 'YNSK69'

}

export const clientNamesArr = [
  "BJCTA", "City of Irondale", "The City of Alabaster", "City of Birmingham", "City of Elmore", "City of Gardendale",
  "Collier Properties", "Lee County", "Hamburger Heaven", "City of Bessemer"
]
