// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLogin } from '../services/api.js';
import bcrypt from 'bcryptjs'; // Import bcryptjs for password comparison
import { Box, Typography, Grid, Paper, TextField, Button } from '@mui/material';
import { styles } from '../css/login.styles.js';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons
import { COLORS1 } from '../util/constants.js';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const [user, setUser] = useState({})

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            if (!username) {
                setError('Username  cannot be empty'); // Set error message
                return; // Exit the function if validation fails
            } else if (!password) {
                setError('Password cannot be empty'); // Set error message
                return; // Exit the function if validation fails
            }

            const response = await fetchLogin(username, password); // Call fetchLogin with username and password
            //console.log(response)
            if (response && response.success) {
               // const testuser = response.user
               // console.log(testuser)
               setUser(response.user)
                sessionStorage.setItem('username', username); // Store username in sessionStorage
                sessionStorage.setItem('userEmail', username);
                sessionStorage.setItem('password', password);
                sessionStorage.setItem('isAuthenticated', 'true');
                console.log(response.token)
                sessionStorage.setItem('token', response.token)
                //console.log(sessionStorage.getItem('isAuthenticated'))
                //console.log(response.user.email)
                navigate('/dashboard'); // Navigate to the form page with username
                onLogin();
            } else {
                setError('Invalid username or password');
            }

        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Failed to fetch user data');
        }
       
    };

    

    return (

        <Box sx={{ width: '100%' }}>
            <Box sx={styles.loginContainer}>
                <Box sx={styles.heading}>
                    <Typography variant="h5" gutterBottom>
                        Dashboard Login
                    </Typography>
                </Box>
                <Box sx={styles.loginForm}>

                    <form onSubmit={handleLogin}>
                        <Grid container spacing={2} sx={styles.loginFormGrid}>
                            <Grid item xs={12}>
                                <Paper sx={styles.loginFormPaper}>
                                    <TextField
                                        label="Username"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        sx={styles.loginFormTextField}
                                    />
                                    <Grid container alignItems="center"> {/* Added container for alignment */}
                                        <Grid item xs>
                                            <TextField
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                sx={styles.loginFormTextField}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                                                variant="text"
                                                sx={{ color: COLORS1.tabColor }} // Change color of the button
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />} {/* Show eye icon based on visibility state */}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Button type="submit"
                                        variant="contained"
                                        sx={styles.loginFormButton}
                                        fullWidth>Login</Button>
                                    {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error if any */}
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </Box>


            </Box>
        </Box>

    );
};

export default Login;