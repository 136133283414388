import axios from 'axios';
import { API_BASE_URL, LOGIN_URL } from '../util/constants';

export const fetchPlayers = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/onsign/players-with-orgType`);
    //console.log('API Response:', response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    //console.log('Parsed data:', data);
    return data;
  } catch (error) {
    // if (error.message === 'Network Error' ||  error instanceof TypeError) {
    //   console.error('Network error: Please check your internet connection.');
    //   const error = 'Network Error: Please Check your internet and refresh';
    //   return error;
    // } 
    throw error;
  }
};

export const fetchTickets = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/zoho/tickets`);
    //console.log("response")
    return response.data;
  } catch (error) {
   
      console.error('Error fetching tickets:', error);
   //return error.message
    throw error;
  }
};


// Function to fetch client names based on email IDs

export const fetchClientByEmail1 = async (email) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/zoho/get-client-by-email`, {
      params: { email }
    });

    //console.log(response)
    return response.data; // Assuming the response contains the client object
  } catch (error) {
    console.error(`Error fetching client for email ${email}:`, error);
    throw error; // Rethrow the error for further handling
  }
};

// Function to fetch client names based on email IDs
export const fetchClientNames = async (email) => {

  //console.log(email)
  email = JSON.stringify(email)
  try {

    const response = await axios.post(`${API_BASE_URL}/zoho/get-client-by-email?email=${email}`);

    const data = await response;
    return data;
  } catch (error) {
    //console.log(email)
    console.error('Error fetching client names:', error);
    throw error; // Rethrow the error for further handling
  }
};

// export const fetchClientNames = async (emailIds) => {
//   const clientNames = [];

//   for (const email of emailIds) {
//     try {
//       const response = await fetch(`${API_BASE_URL}/zoho/get-client-by-email?email=${encodeURIComponent(email)}`);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       clientNames.push({ email, name: data.name }); // Assuming the response contains a single client object with a name field
//     } catch (error) {
//       console.error(`Error fetching client for email ${email}:`, error);
//       clientNames.push({ email, name: 'Error' }); // Return 'Error' for other errors
//     }
//     await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before the next request
//   }

//   return clientNames; // Returns an array of client objects
// };


export const fetchLogin = async (username, password) => {
  try {
    const response = await fetch(LOGIN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: username, password }),
    });

    if (!response.ok) {
      throw new Error('Login failed!'); // Handle error response
    }

    const data = await response.json(); // Parse the JSON response
    return data; // Return the response data
  } catch (error) {
    console.error('Error during login:', error);
    throw error; // Rethrow the error for further handling
  }

}