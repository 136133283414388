import { format, toZonedTime } from 'date-fns-tz';
import { fetchClientNames } from '../services/api';
import iconPaths from './iconPaths';
import TapasIcon from '@mui/icons-material/Tapas';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { styles } from '../css/players.styles';

export const processFetchedData = (response) => {
  let fetchedData = response.data || response;

  // Check if fetchedPlayers is an object and not an array
  if (typeof fetchedData === 'object' && !Array.isArray(fetchedData)) {
    fetchedData = Object.values(fetchedData); // Convert object to array
  }

  // Check if fetchedPlayers is an array
  if (!Array.isArray(fetchedData)) {
    throw new Error('Fetched players data is not in the expected format');
  }

  return fetchedData; // Return the array of players
};

// export const filterPlayers = (players, selectedOrgName, selectedPlayerType, selectedTimezone) => {
//   if (!Array.isArray(players)) return [];
//   let filtered = players;
//   //console.log(filtered.length)
//   //console.log(selectedPlayerType)
//   if (selectedOrgName) {
//     filtered = filtered.filter(player => player.orgName === selectedOrgName);
//   }
//   //console.log(filtered.length)
//   // if (selectedPlayerType) {
//   //   filtered = filtered.filter(player => player.playerType.type === selectedPlayerType);
//   // }
//   //console.log(filtered.length)
//   if (selectedTimezone && selectedTimezone.length > 0) {
//     filtered = filtered.filter(player => selectedTimezone.includes(player.timezone));
//   }
//   //console.log(filtered.length)
//   return filtered;
// };

export const filterPlayers = (players, filters) => {
  //console.log(filters)
  const selectedOrgName = filters.orgName
  const selectedPlayerType = filters.playerType
  const selectedTimezone = filters.timezone
  const selectedOrgType = filters.orgType
  const selectedReseller = filters.reseller

  if (!Array.isArray(players)) return [];
  let filtered = players;

  if (selectedOrgName) {
    filtered = filtered.filter(player => player.orgName === selectedOrgName);
  }

  if (selectedPlayerType) {
    filtered = filtered.filter(player => player.playerType.type === selectedPlayerType);
  }
  if (selectedTimezone && selectedTimezone.length > 0) {
    filtered = filtered.filter(player => selectedTimezone.includes(player.timezone));
  }
  if (selectedOrgType) {
    filtered = filtered.filter(player => player.orgType === selectedOrgType);
  }
  if (selectedReseller) {
    filtered = filtered.filter(player => player.reseller === selectedReseller);
  }
  //console.log(filtered)

  return filtered;
};

export const filterTickets = (tickets, selectedClient, selectedAgent, ticketsFlag) => {

  //console.log(selectedClient,"******",selectedAgent,"******",ticketsFlag)

  if (!Array.isArray(tickets.openTickets) || tickets.openTickets.length === 0) return [];

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  let filtered = [];
  const flag = ticketsFlag //sessionStorage.getItem('ticketsFlag')
  //console.log(flag)
  if (flag === null || flag === 'open') {

    filtered = tickets.openTickets || []

    filtered = filtered.filter(
      ticket => {
        return new Date(ticket.createdTime) >= oneWeekAgo
      }
    );
  } else if (flag === 'openAll') {
    filtered = tickets.openTickets || []
  } else if (flag === 'closedAll') {
    filtered = tickets.closedTickets || []
  }
  if (selectedClient) {
    filtered = filtered.filter(ticket => ticket.clientName === selectedClient);
  }
  if (selectedAgent) {
    filtered = filtered.filter(ticket => ticket.agentName === selectedAgent)
  }
  return filtered;
};


export const sortPlayers = (players, sortColumn, sortDirection) => {
  if (!Array.isArray(players)) return [];

  return [...players].sort((a, b) => {
    if (sortColumn === 'lastSeen') {
      // Convert to Date objects for comparison
      const dateA = a[sortColumn] ? new Date(a[sortColumn]) : new Date(0);
      const dateB = b[sortColumn] ? new Date(b[sortColumn]) : new Date(0);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    }

    const valueA = a[sortColumn] || '';
    const valueB = b[sortColumn] || '';

    if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
};


/**export const calculateDaysOffline1 = (lastSeen) => {
  if (!lastSeen) return 'N/A';
  const lastSeenDate = new Date(lastSeen);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - lastSeenDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}; */

export const calculateDaysOffline = (lastSeen) => {
  const lastSeenDate = new Date(lastSeen);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - lastSeenDate);
  //console.log(Math.ceil(diffTime / (1000 * 60 * 60 * 24)))
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};




/** export const calculateOfflineDays = (players) => {
  const oneWeekOrLess = [];
  const moreThanOneWeek = [];

  const currentDate = new Date();

  players.forEach(player => {
    const lastOnlineDate = new Date(player.lastOnline);
    const daysOffline = differenceInDays(currentDate, lastOnlineDate);

    if (daysOffline <= 7) {
      oneWeekOrLess.push(player);
    } else {
      moreThanOneWeek.push(player);
    }
  });

  return {
    oneWeekOrLess,
    moreThanOneWeek,
  };
}; */


export const getClientNames = async (emailIds) => {
  try {
    const clientNames = await fetchClientNames(emailIds);
    //console.log(clientNames)
    const clientMap = clientNames.reduce((acc, client) => {
      acc[client.email] = client.name; // Create a mapping of email to client name
      return acc;
    }, {});
    // Now you can use clientMap to associate client names with tickets
  } catch (error) {
    console.error('Failed to fetch client names:', error);
  }
};

// Function to format the date
export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Extracting the components
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Constructing the formatted date string
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`; // Format: MM/DD/YYYY HH:mm:ss
};

// Function to get local time for a specific timezone
export const getLocalTimeForTimezone = (timezone) => {
  const date = new Date(); // Current date and time
  const zonedDate = toZonedTime(date, timezone); // Convert to the specified timezone
  const localTime = timezone + " - " + format(zonedDate, 'MM-dd-yyyy HH:mm:ssXXX', { timeZone: timezone }); // Format the date
  return localTime;
}



// Function to convert UTC date to a given timezone
export const convertUtcToTimezone = (timezone, utcDate) => {
  // Convert the UTC date to the specified timezone
  const zonedDate = toZonedTime(utcDate, timezone);

  // Format the date in a readable format
  const formattedDate = format(zonedDate, 'MM-dd-yyyy HH:mm:ssXXX', { timeZone: timezone });
  return formattedDate;
}


export const getClientNamesByEmail = async (tickets) => {
  const emailIds = Array.from(
    new Set(
      tickets.map(ticket => ticket.email).filter(email => email)
    )
  )
  //console.log(emailIds)
  const emails = emailIds.join()
  const clients = await fetchClientNames(emails)
  const clientNames = clients.data
  //console.log(clientNames)
  const clientMap = new Map(clientNames.map(client => [client.email, client.client ? client.client : ""]));
  //console.log(clientMap)
  // Iterate over tickets and add client names
  return tickets.map(ticket => {
    const clientName = clientMap.get(ticket.email) || ''; // Default if not found

    return {
      ...ticket, // Spread the existing ticket properties
      clientName, // Add the client name
      agentName: ticket.assignee ? ticket.assignee.firstName + " " + ticket.assignee.lastName : "NA"
    };
  });

}

export const filterAndSortTickets = (tickets) => {
  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 15);
  return tickets
    .filter(ticket => {

      // Ensure ticket status is checked correctly
      const isOpen = ticket.status && ticket.status.toLowerCase() !== 'closed';

      return isOpen //&& isWithinLastWeek; // Only include open tickets created within the last week
    })
    .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime)); // Sort by latest first
};


export const getUniqueValues = (array, keys) => {
  const uniqueValues = {};
  keys.forEach(key => {
    uniqueValues[key] = [...new Set(array.map(item => item[key]))];
  });
  return uniqueValues;
};



export const filterPlayersByStatus = (players, activeTab, allFlag) => {
  //console.log(players)
  //const now = new Date();
  return players.filter(player => {
    //const lastOnline = new Date(player.lastOnline);
    //const diffDays = Math.floor((now - lastOnline) / (1000 * 60 * 60 * 24));
    if (allFlag) {
      if (activeTab === 0) {
        return !player.isConnected && !player.workingDays.isPlanned;
      } else if (activeTab === 1) {
        return player.isConnected;
      }
      else if (activeTab === 2) {
        return !player.isConnected && player.workingDays.isPlanned && player.offLineDays < 7;
      }
    } else {
      if (activeTab === 0) {
        return player.offLineDays >= 7;
      } else if (activeTab === 1) {
        return player.offLineDays <= 7 && player.offLineDays > 0;
      } else if (activeTab === 2) {
        return player.offLineDays === 0;
      }
    }
    return false;
  });
};




export const determinePlayerType = (player) => {

  let playerType = {}
  if (player.name.startsWith('M') && !isNaN(player.name[1])) {
    //console.log(player.name)
    playerType.type = 'Mobile Player';
    playerType.iconPath = <DepartureBoardIcon />
  } else if (player.name.startsWith('EX') && !isNaN(player.name[2])) {
    playerType.type = 'Exterior Player';
    playerType.iconPath = <TapasIcon />
  } else {
    playerType.type = 'Interior Player';
    playerType.iconPath = <ApartmentIcon />
  }
  return playerType;
}

export const getPlayerIcon = (playerType) => {
  switch (playerType) {
    case 'Mobile Player':
      return <img src={iconPaths.mobile} alt="Mobile Player Icon" style={{ width: '20px', height: '20px' }} />;
    case 'Exterior Player':
      return <img src={iconPaths.exterior} alt="Exterior Player Icon" style={{ width: '20px', height: '20px' }} />;
    case 'Interior Player':
      return <img src={iconPaths.interior} alt="Interior Player Icon" style={{ width: '20px', height: '20px' }} />;
    default:
      return null; // or a default icon
  }
};


// Helper function to parse time string to a comparable number (e.g., "08:00" -> 480)
const parseTime = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes; // Convert to total minutes
};

// Helper function to format time back to "HH:mm"
const formatTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};


export const getWorkingDays = (player) => {

  let workingDays = '';

  if (!player || !player.workingHours || !player.workingHours[0]) {
    return workingDays;
  }


  const count = player.workingHours.length
  const weekDays = [];

  const startTimesMap = new Array(7).fill(Infinity); // Initialize with Infinity for earliest start times
  const endTimesMap = new Array(7).fill(-Infinity); // Initialize with -Infinity for latest end times

  player.workingHours.forEach(day => {
    day.weekDays.forEach(dayValue => {
      if (!weekDays.includes(dayValue)) {
        weekDays.push(dayValue); // Add unique week days
      }

      // Update earliest start time
      const startTime = parseTime(day.startTime);
      if (startTime < startTimesMap[dayValue]) {
        startTimesMap[dayValue] = startTime;
      }

      // Update latest end time
      const endTime = parseTime(day.endTime);
      if (endTime > endTimesMap[dayValue]) {
        endTimesMap[dayValue] = endTime;
      }
    });
  });

  // Convert start and end times back to readable format
  const earliestStartTimes = startTimesMap.map(time => (time === Infinity ? null : formatTime(time)));
  const latestEndTimes = endTimesMap.map(time => (time === -Infinity ? null : formatTime(time)));
  // if (count === 3) {
  //   console.log(player.workingHours)
  //   console.log('Unique Week Days:', weekDays);
  //   console.log('Earliest Start Times:', earliestStartTimes);
  //   console.log('Latest End Times:', latestEndTimes);

  // }

  workingDays = { weekDays: weekDays, startTime: earliestStartTimes, endTime: latestEndTimes };
  checkPlannedOffline(player, workingDays)

  return workingDays;


}

export const getCurrentDateTimeInTimezone = (timezone) => {
  const date = new Date(); // Get the current date and time
  const zonedDate = toZonedTime(date, timezone); // Convert to the specified timezone
  const formattedDate = format(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: timezone });
  // Get the hours and day of the week
  const hours = zonedDate.getHours(); // Get current hours
  const dayOfWeek = format(zonedDate, 'EEEE'); // Get the full name of the day (e.g., "Monday")

  return { hours, dayOfWeek };
};

const checkPlannedOffline = (player, workingDays) => {

  const { hours: currentHour, dayOfWeek: currentDay } = getCurrentDateTimeInTimezone(player.timeZone); // Destructure the returned objectlet isPlanned = false;
  let isPlanned = false;

  if (!player.isConnected) {
    if (workingDays.weekDays.includes(currentDay)) {
      // Check if the current hour is within any of the start times
      const startHours = workingDays.startTime.map(time => time ? parseInt(time.split(':')[0], 10) : Infinity); // Extract start hours
      const endHours = workingDays.endTime.map(time => time ? parseInt(time.split(':')[0], 10) : -Infinity); // Extract end hours

      // Check if current hour is less than or equal to any start hour and greater than or equal to any end hour
      const isWithinPlannedTime = startHours.some(startHour => currentHour <= startHour) &&
        endHours.some(endHour => currentHour >= endHour);

      isPlanned = isWithinPlannedTime; // Set isPlanned based on the check
    } else {
      if (workingDays.weekDays.length > 0) {
        const lastWeekDay = workingDays.weekDays[workingDays.weekDays.length - 1]; // Get the last value in weekDays
        const dayDifference = (currentDay - lastWeekDay + 7) % 7; // Calculate the difference, ensuring it's positive

        // Check if the difference is an exact number of days (not zero)
        if (dayDifference > calculateDaysOffline(player.lastSeen)) {
          isPlanned = true;
        }
      }
    }
  }
  // weekDays.includes(currentTime.getDay())? workingDays.isPlanned = false: workingDays.isPlanned = true


  workingDays.isPlanned = isPlanned;
  if (workingDays.weekDays.length === 3) {
    //console.log(workingDays)
  }
  return workingDays;
}


export const getOfflineDays = (lastSeenAgo) => {
  if (typeof lastSeenAgo !== 'number' || lastSeenAgo < 0) {
    throw new Error('Input must be a non-negative number');
  }

  let days = lastSeenAgo / 172800
  let returnValue = ''
  if (days <= 1) {
    returnValue = lastSeenAgo / 1440 + "hrs"
  } else {
    returnValue = Math.ceil(days) + "days"
  }

  return lastSeenAgo / 172800; // 1440 minutes in a day
}

export const loadFromSessionStorage = (key, setter) => {
  const value = sessionStorage.getItem(key);

  if (value !== null) {
    setter(value); // Set the value if it exists

  }
};
