import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Paper, Grid, CircularProgress, Collapse } from '@mui/material';
import TicketsTable from './TicketsTable';
import TicketFilters from './TicketFilters';
import { filterTickets } from '../../util/dashboardUtil';
import { styles } from '../../css/tickets.styles';



function TicketsDashboard({ tickets, loading, error }) {
  //console.log(tickets.openTickets)
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [clientFilter, setClientFilter] = useState('');
  const [agentFilter, setAgentFilter] = useState('');
  const [tableHeader, setTableHeader] = useState("Open Tickets in the Last Week")
  const [ticketsFlag, setTicketsFlag] = useState('open')
  //console.log(tickets)

  const sortTickets = useMemo(() => {
    const filtered = filterTickets(tickets, clientFilter, agentFilter, ticketsFlag);
    //console.log(filtered)
    return filtered;
  }, [tickets, clientFilter, agentFilter, tableHeader, ticketsFlag]);
  //console.log(sortTickets)

  useEffect(() => {
    setFilteredTickets(sortTickets);


  }, [sortTickets]);

  useEffect(() => {
    //sessionStorage.setItem('ticketsFlag', 'open')
    setTicketsFlag('open')
    setClientFilter('')
    setAgentFilter('')
  }, [])



  const showTickets = (type) => {
    if (type === 'open') {
      //sessionStorage.setItem('ticketsFlag', 'openAll')
      setTicketsFlag('openAll')
      setTableHeader("All Open Tickets")
      //setClientFilter('')
      //setAgentFilter('')
    } else if (type === 'closed') {
      //sessionStorage.setItem('ticketsFlag', 'closedAll')
      setTicketsFlag('closedAll')
      setTableHeader("All Closed Tickets")
      //setClientFilter('')
      //setAgentFilter('')
    }
  }


  return (
    <Paper sx={{ p: 2, }}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error" variant="h6">{error instanceof Error ? error.message : error}</Typography>
      ) : (
        <Grid>
          <Typography variant="h4" gutterBottom>
            Tickets Overview
          </Typography>
          {tickets ?
            <Grid>
              <Grid container spacing={3} >
                <Grid item xs={12} md={6} >
                  <Paper sx={styles.paperClasses} onClick={() => { showTickets('open') }}>
                    <Typography variant="h6" style={{ cursor: 'pointer' }} > Open Tickets </Typography>
                    <Typography variant="h3">{tickets.openTickets && tickets.openTickets.length}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper sx={styles.paperClasses} onClick={() => { showTickets('closed') }}>
                    <Typography variant="h6" style={{ cursor: 'pointer' }} >Closed Tickets</Typography>
                    <Typography variant="h3">{tickets.closedTickets && tickets.closedTickets.length}</Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 2 }}>

                <Typography variant="h5" gutterBottom sx={{ pl: 3 }}>
                  {tableHeader}
                </Typography>

                <Grid item xs={12}>
                  <TicketFilters
                    tickets={filteredTickets}
                    clientFilter={clientFilter}
                    setClientFilter={setClientFilter}
                    agentFilter={agentFilter}
                    setAgentFilter={setAgentFilter}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ mt: 2 }}>

                <Grid item xs={12}>
                  <TicketsTable tickets={filteredTickets} loading={loading} error={error} />



                </Grid>
              </Grid>
            </Grid>


            : <Typography>No tickets found</Typography>

          }
        </Grid>

      )}
    </Paper>
  );
}

export default TicketsDashboard;