import React, { useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styles } from '../../css/players.styles';
import { timeZones } from '../../util/timeZones';
import SelectInput from '../common/SelectInput';
import { loadFromSessionStorage } from '../../util/dashboardUtil';


function PlayerFilters({ inputLists, players, allFlag, filterProps }) {

  const {
    selectedOrgName,
    setSelectedOrgName,
    selectedPlayerType,
    setSelectedPlayerType,
    selectedTimezone,
    setSelectedTimezone,
    selectedOrgType,
    setSelectedOrgType,
    selectedReseller,
    setSelectedReseller,
  } = filterProps;
  
  const timezones = inputLists.timezone;
  const uniqueTimezoneData = timezones && timezones.reduce((acc, timezone) => {
    const timezoneData = timeZones.find(tz => tz.value.includes(timezone));
    if (timezoneData && !acc.some(item => item.value === timezoneData.value)) {
      acc.push(timezoneData); // Add unique timezoneData to the accumulator
    }
    return acc; // Return the accumulator for the next iteration
  }, []); // Initialize with an empty array

  useEffect(() => {
    if (allFlag) {
      loadFromSessionStorage('selectedOrgName', setSelectedOrgName);
      loadFromSessionStorage('selectedPlayerType', setSelectedPlayerType);
      loadFromSessionStorage('selectedTimezone', setSelectedTimezone);
      loadFromSessionStorage('selectedOrgType', setSelectedOrgType);
      loadFromSessionStorage('selectedReseller', setSelectedReseller);
    } else {
      loadFromSessionStorage('selectedOrgNameBHM', setSelectedOrgName);
      //setSelectedOrgName(sessionStorage.getItem('selectedOrgNameBHM'))
      setSelectedPlayerType('')
      setSelectedTimezone('')
    }

  }, [setSelectedPlayerType, setSelectedOrgName, setSelectedTimezone, setSelectedOrgType, setSelectedReseller]);

  const handleFilterChange = (value, type, isCheckbox = false) => {
    // Handle dropdown logic
    const storageKeys = {
      1: { setter: setSelectedOrgName, key: allFlag ? 'selectedOrgName' : 'selectedOrgNameBHM' },
      2: { setter: setSelectedPlayerType, key: 'selectedPlayerType' },
      3: { setter: setSelectedTimezone, key: 'selectedTimezone' },
      4: { setter: setSelectedOrgType, key: 'selectedOrgType' },
      5: { setter: setSelectedReseller, key: 'selectedReseller' },
    };

    if (storageKeys[type]) {
      storageKeys[type].setter(value); // Update state
      sessionStorage.setItem(storageKeys[type].key, value); // Store in sessionStorage
    }
  };

  return (
    <Grid>
      <Grid container spacing={2} sx={styles.filtersContainer}>
        <Grid item xs={12} md={3}> {/* Adjusted to take 3 columns for each dropdown */}
          <SelectInput
            selectedValue={selectedOrgName}
            selectType="Client"
            inputList={inputLists.orgName}
            handleFilterChange={handleFilterChange}
            type={1}
          />
        </Grid>

        {allFlag && (
          <>
            <Grid item xs={12} md={3}> {/* Adjusted to take 3 columns for each dropdown */}
              <SelectInput
                selectedValue={selectedOrgType}
                selectType="Org Types"
                inputList={inputLists.orgType}
                handleFilterChange={handleFilterChange}
                type={4}
              />
            </Grid>

            <Grid item xs={12} md={3}> {/* Adjusted to take 3 columns for each dropdown */}
              <SelectInput
                selectedValue={selectedReseller}
                selectType="Resellers"
                inputList={inputLists.reseller}
                handleFilterChange={handleFilterChange}
                type={5}
              />
            </Grid>


            <Grid item xs={12} md={3}> {/* Adjusted to take 3 columns for timezone dropdown */}
              <FormControl fullWidth sx={styles.formControl}>
                <InputLabel id="timezone-select-label" sx={styles.inputLabel}>All Timezones</InputLabel>
                <Select
                  labelId="timezone-select-label"
                  id="timezone-select"
                  value={selectedTimezone}
                  label="Filter by Timezone"
                  onChange={(e) => handleFilterChange(e.target.value, 3)}
                  sx={styles.select}
                >
                  <MenuItem value="">
                    <em>All Timezones</em>
                  </MenuItem>
                  {uniqueTimezoneData && uniqueTimezoneData.map((timezone) => {
                  return(
                      <MenuItem key={timezone.value} value={timezone.value}>
                        {timezone.label}
                      </MenuItem>
                  )
                   
                  }) }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}> {/* Adjusted to take 3 columns for player type dropdown */}
              <FormControl fullWidth sx={styles.formControl}>
                <InputLabel id="player-type-select-label" sx={styles.inputLabel}>All Player Types</InputLabel>
                <Select
                  labelId="player-type-select-label"
                  id="player-type-select"
                  value={selectedPlayerType}
                  label="Filter by Player Type"
                  onChange={(e) => handleFilterChange(e.target.value, 2)}
                  sx={styles.select}
                >
                  <MenuItem value="">
                    <em>All Player Types</em>
                  </MenuItem>
                  {inputLists.playerTypes && inputLists.playerTypes.map((playerType) => {
                  return(
                      <MenuItem key={playerType} value={playerType}>
                        {playerType}
                      </MenuItem>
                  )
                   
                  }) }
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

      </Grid>
    </Grid>
  );

};

export default PlayerFilters;