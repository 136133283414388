import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styles } from '../../css/players.styles';

const SelectInput = ({ selectedValue, selectType, inputList, handleFilterChange, type }) => {

    return (

        <FormControl fullWidth sx={styles.formControl}>
            <InputLabel id="org-name-select-label" sx={styles.inputLabel}>All {selectType}</InputLabel>
            <Select
                labelId="org-name-select-label"
                id="org-name-select"
                value={selectedValue}
                label="Filter by Organization Name"
                onChange={(e) => handleFilterChange(e.target.value, type)}
                sx={styles.select}
            >
                <MenuItem value="">
                    <em>All {selectType}</em>
                </MenuItem>
                {inputList && inputList.map((value) => (
                    <MenuItem key={value} value={value}>{value}</MenuItem>
                ))}
            </Select>
        </FormControl>

    )
}

export default SelectInput


